Global Styles
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .full-width-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#ffffff;
  }
  
  .content-container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    background-color: #333333;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  
  .header-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1170px;
    padding: 0 20px;
    height: 38px;
  }
  
  .header-info span {
    margin: 0 10px;
  }
  
  .main-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .feature-title {
    font-size: 2em;
    margin: 20px 0;
  }


  


/* Card styling */
.vehicle-grid {
  display: grid;
  gap: 20px;
  justify-content: center;
  width: 100%; 
  max-width: 1170px; /* Limit max width */
  margin: 0 auto; /* Center align */
}


.vehicle-card {
  display: grid;
  grid-template-areas:
    "ad ad ad"
    "image title price"
    "image details details"
    "image admin-options admin-options"
    "imageIcons imageIcons imageIcons"; 
  grid-template-columns: 350px 1fr auto;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 1000px;
}

/* Image container styling with updated largeThumbUrl for main image */
.vehicle-image-container {
  grid-area: image;
  width: 100%;
  height: 263px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  overflow: hidden;
}

/* Styling for image thumbnails in the new grid area */
.vehicle-image-icons {
  grid-area: imageIcons;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  gap: 10px;
  padding: 10px;
  justify-content: start; /* Align thumbnails to the start */
}

.image-icon {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.image-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid black;
} 

.vehicle-admin-options-container {
  grid-area: admin-options;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Split left and right sections */
  padding: 10px;
}

.vehicle-admin-buttons-left {
  display: flex;
  gap: 10px; /* Space between the edit and save buttons */
}




/* Shared styling for edit and delete buttons */
.vehicle-edit-button,
.vehicle-delete-button {
  height: 35px;
  width: 35px;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 10px;
}

/* Unique styling for the save button */
.vehicle-save-button {
  height: 35px;
  border: none;
  background: none;
  cursor: pointer;
}



/* Ad Row styling */
.vehicle-ad-row {
  grid-area: ad;
  background-color: #4f4f4f;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  text-align: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Image container styling */
.vehicle-image-container {
  grid-area: image;
  width: 100%;
  height: 263px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  overflow: hidden;
  cursor: pointer;
}

/* Image styling */
.vehicle-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Title and Price */
.vehicle-title {
  grid-area: title;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  padding: 10px;
}

.vehicle-price {
  grid-area: price;
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  text-align: right;
  padding: 10px;
  margin: 0;
}

/* Vehicle Details */
.vehicle-details {
  grid-area: details;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 15px;
  padding: 10px;
}

.vehicle-details p {
  margin: auto auto auto 0;
}




/* Styling for input fields in edit mode */
.vehicle-input {
  width: 100%;
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

/* Label styling */
.input-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Styling for the title and price area in edit mode */
.edit-mode-title {
  grid-area: title;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.price-label {
  margin-left: auto; /* Align price input to the right */
}

/* Normal mode styling for price */
.vehicle-price {
  grid-area: price;
}

/* Grid area assignments */
.vehicle-ad-row {
  grid-area: ad;
}

.vehicle-title {
  grid-area: title;
}

.vehicle-details {
  grid-area: details;
}






.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Keeps background click layer behind content */
}

.modal-content {
  max-width: 90%;
  max-height: 100vh;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1; /* Ensure modal content is above the background */
  display: flex;
  flex-direction: column;
}

.modal-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
}

.modal-image {
  max-width: 100%;
  max-height: 100vh; /* Reduce max height to leave space for controls */
  border-radius: 8px;
  object-fit: contain; /* Keep image aspect ratio */
}

.modal-controls {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  z-index: 2; 
  padding: 10px;
  width: 100%;

  gap: 15px;
  box-sizing: border-box;
}

.modal-controls button {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid black;
  border-radius: 5px;
}

.modal-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}











/* Admin Page Form Styling */
.form-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="tel"],
.form-group input[type="url"],
.form-group input[type="file"] {
  padding: 8px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.submit-button {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}





.vehicle-marketplace {
  display: flex;
  align-items: center;

}

.marketplace-link {
  color: #000; /* Default color */
  text-decoration: none;
  margin-right: 10px;
}

.marketplace-link:hover {
  color: #007bff; /* A nice blue color for hover */
}




/* General header styling */
.header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #333333;
}

.header-info {
  background-color: #333333;
  color: white;
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  margin: 0 auto;
  font-weight: bold;
  align-items: center;
}

.header-contact {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between phone number and logout icon */
}

/* Admin and Logout button icons */
.admin-icon,
.logout-icon {
  height: 30px;
  cursor: pointer;
}



.header-logo {
  background-color: #000000;
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.header-logo img {
  max-width: 200px;
  height: auto;
}

.header-link {
  color: white;
  text-decoration: none;
}

.header-link:hover {
  text-decoration: underline;
}

/* Mobile-specific styling */
.header-logout-mobile {
  display: none; /* Hidden by default */
  flex-direction: column; /* Ensure column layout for logout and admin buttons */
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 10px 0;
}



.email-login-container{
  width: 450px;
}


.email-login-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.login-form {
  display: grid;
  gap: 15px;
}

.login-form label {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
}

.login-input {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
}




/* Container for the grid of image previews */
.image-preview-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

/* Individual image preview container */
/* .image-preview {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
} */

/* Style for the image itself */
.image-preview img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd; /* Adds a border to separate the image from the input */
}

/* Style for the order input */
/* .order-input {
  width: 60px;
  margin: 3px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
} */



/* Individual image preview container */
.image-preview {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

/* Style for the order input */
.order-input {
  width: 60px;
  margin: 3px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

/* Style for the update button */
.update-button {
  margin-top: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}





/* Container for the upload progress */
.progress-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px;
  text-align: center;
  font-size: 12px;
}

/* Style for the progress bar */
.progress-container progress {
  width: 100%;
  margin-top: 4px;
  height: 6px;
  appearance: none;
}

/* Progress bar customization (for WebKit browsers) */
.progress-container progress::-webkit-progress-bar {
  background-color: #ddd;
  border-radius: 3px;
}

.progress-container progress::-webkit-progress-value {
  background-color: #4caf50;
  border-radius: 3px;
}

/* Progress bar customization for Firefox */
.progress-container progress::-moz-progress-bar {
  background-color: #4caf50;
  border-radius: 3px;
}










  
  /* Media Queries */
  @media (min-width: 768px) {
    .content-container {
      max-width: 750px;
      padding: 10px;
    }
  
    .feature-title {
      font-size: 1.8em;
    }

    .vehicle-card {
      width: 650px; 
    }



  }
  
  @media (min-width: 992px) {
    .content-container {
      max-width: 970px;
    }
  
    .feature-title {
      font-size: 1.9em;
    }


    .vehicle-card {
      width: 850px; 
    }


  }
  
  @media (min-width: 1200px) {
    .content-container {
      max-width: 1170px;
    }

    .vehicle-card {
      width: 1000px; 
    }

  }
  

  
  @media (max-width: 768px) {

    .content-container {
      width: 100%;
      padding: 0px;
      margin: 10px auto;
    }

    .vehicle-grid {
      width: 100%; 
    }


    .vehicle-card {
      display: grid;
      grid-template-areas:
        "ad ad"
        "title title"
        "image image"
        "price price"
        "details details"
        "admin-options admin-options"
        "imageIcons imageIcons";
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto;
      width: 90%; 
      margin: auto;
    }

    /* .vehicle-image-container{
      background-color: white;
      height: unset;
    } */


    .vehicle-image-container {
      width: 100%; /* Ensure it takes full width of the container */
      height: auto; /* Remove fixed height */
      aspect-ratio: 16 / 9; /* Maintain a 16:9 ratio, adjust as needed */
      /* background-color: white; */
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }


    /* Styling for image thumbnails in the new grid area */
    .vehicle-image-icons {
      grid-template-columns: repeat(auto-fit, 45px);
    }


    .vehicle-image {
      width: 100%; /* Scale image to fill the container's width */
      height: auto; /* Maintain aspect ratio */
      object-fit: contain; /* Ensure the image doesn't overflow */
    }

    .image-icon {
      cursor: pointer;
      height: 45px;
      width: 45px;
    }

    

    .vehicle-ad-row,
    .vehicle-title,
    .vehicle-price {
      text-align: center; /* Center-align these elements */
    }

    /* Change vehicle-details grid to 1-column layout */
    .vehicle-details {
      grid-template-columns: 1fr; /* Set to a single column */
    }




    .email-login-container{
      width: 80%;
      margin: 10px auto;
    }


    .form-container {
      width: 85%;
      max-width: unset;

    }




    .header-link {
      font-size: small; /* Adjust font size for mobile */
    }
    
    /* Show mobile logout button, hide desktop logout on smaller screens */
    .header-contact img.logout-icon {
      display: none; /* Hide logout icon in header-contact on mobile */
    }


    /* Hide admin and desktop logout on mobile */
    .header-contact .admin-icon,
    .header-contact .logout-icon {
      display: none;
    }


    .admin-icon{
      margin-top: 5px;
    }

    .header-logout-mobile {
      display: flex; /* Display logout icon below logo on mobile */
      align-items: center;
      justify-content: center;
      background-color: white;
    }











  }
