.checkbox-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .checkbox-button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    height: 35px;
  }
  
  .checkbox-image {
    width: 35px;
    height: 35px;
  }
  
  .checkbox-label {
    font-weight: bold;
    font-size: 1.2em;
    margin-left: 5px;
  }
  