.loading-indicator-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Default, but can be overridden by prop */
    width: 100%;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #0026ff; /* Black spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .loading-text {
    font-style: italic;
    margin-left: 5px;
    font-weight: bold;
    color: blue;
  }
  
  /* Animation for spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  