/* Styles for the EmailLoginScreen */



.login-button {
    padding: 12px 0;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    width: 200px;
    margin: auto;
}

.login-button:hover {
    background-color: #45a049;
}

.error-box {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}
