/* PreviewProgress.css */
.preview-progress {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.progress-bar-container {
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 10px;
    margin-top: 8px;
}

.progress-bar {
    background-color: #4caf50;
    height: 100%;
    transition: width 0.3s ease;
}
